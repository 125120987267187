import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import './style.css';

// Check if the character is not an alphabet letter
const isInAlphabet = (char) => {
  return /^[A-Za-z]$/.test(char);
}

// Return an ordered map of letters from a list of words
const getLetterMap = (brands) => {
  const letterMap = new Map();

  brands.forEach(({ node }) => {
    const brandName = node.name;
    let letter = brandName[0].toUpperCase();

    if (!isInAlphabet(letter)) letter = "#";

    if (!letterMap.get(letter)) letterMap.set(letter, brandName);
  });

  return letterMap;
}

const AlphabetScroll = (props) => {
  const { brands } = props;
  const [activeKey, setActiveKey] = useState(null);

  const brandLetterMap = getLetterMap(brands);
  const keyArr = Array.from(brandLetterMap.keys());

  // Check if an element's rectangle is in the current viewport
  const isInViewport = (elemRect) => {
    if (!elemRect) return false;

    return elemRect.bottom > 0 && elemRect.top <= (window.innerHeight || document.documentElement.clientHeight);
  }

  // Controls the position of the scrolling alphabet bar
  const handleStickyScroll = () => {
    const scrollElem = document.querySelector('.alphabet-selector');
    const scrollElemRect = scrollElem.getBoundingClientRect();
    const scrollOffset = (window.innerHeight - scrollElemRect.height)/2; // Position scroll bar in the middle of the page
    const header = document.getElementById('header')?.getBoundingClientRect();
    const footer = document.getElementById('footer')?.getBoundingClientRect();
    const footerTop = footer ? footer.top : 0;
    const headerBottom = header ? header.bottom : 0;

    if (isInViewport(footer)) {
      scrollElem.style.position = 'absolute';
      scrollElem.style.top = "";
      scrollElem.style.bottom = `${scrollOffset}px`;
    } else if (isInViewport(header)) {
      if (!(footerTop - scrollOffset*2 <= window.innerHeight)) {
        scrollElem.style.position = 'fixed';
        scrollElem.style.bottom = '';
        scrollElem.style.top = `${headerBottom + scrollOffset}px`;
      }
    } else {
      scrollElem.style.position = 'fixed';
      scrollElem.style.top = `${scrollOffset}px`;
    }
  }

  const handleClick = (letter) => {
    let headerOffset = 0;

    const targetElement = document.getElementById(brandLetterMap.get(letter));
    const targetElementTop = targetElement.getBoundingClientRect().top;

    if (targetElementTop < 0) {
      const header = document.getElementById('header');
      headerOffset = header ? header.offsetHeight : headerOffset;
    }

    const offsetFromTop = targetElementTop + window.scrollY - headerOffset;

    window.scrollTo({
      top: offsetFromTop,
      behavior: 'smooth'
    });

    setActiveKey(letter)
  };

  useEffect(() => {
    window.addEventListener("scroll", handleStickyScroll);

    handleStickyScroll();

    return () => {
      window.removeEventListener("scroll", handleStickyScroll);
    }
  }, [])

  return (
    <div
      className="alphabet-selector"
    >
      <Col style={{ borderRadius: "15px" }} >
        {keyArr.map((letter) => {
          return (
            <Row key={letter}>
              <button
                id={letter}
                className={`alphabet-selector-button ${activeKey === letter ? "active" : ""}`}
                onClick={() => handleClick(letter)}
              >
                <span className="alphabet-selector-text">{letter}</span>
              </button>
            </Row>
          );
        })}
      </Col>
    </div>
  );
}

AlphabetScroll.propTypes = {
  brands: PropTypes.arrayOf(PropTypes.shape({
    node: PropTypes.shape({
      name: PropTypes.string
    })
  })).isRequired,
};

export default AlphabetScroll;