import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get, groupBy } from 'lodash';
import { Form } from '@ant-design/compatible';
import { Checkbox, Col, Radio, Row, Select } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import GA4 from '~/ga4';
import StockInfo from './StockInfo';

const { Item: FormItem } = Form;
const { Option, OptGroup } = Select;
const { Group: RadioGroup } = Radio;

const radioStyle = {
  display: 'inline-flex',
  whiteSpace: 'pre-wrap',
};

const INS_SUFFIX = "_insurance";
const PRI_SUFFIX = "_priority";

class ShippingView extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      cart: PropTypes.shape({
        shippingMethods: PropTypes.shape({
          edges: PropTypes.arrayOf(PropTypes.object),
        }),
        stores: PropTypes.shape({
          edges: PropTypes.arrayOf(PropTypes.object),
        }),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    form: PropTypes.shape({
      getFieldValue: PropTypes.func.isRequired,
      setFieldsValue: PropTypes.func.isRequired,
      getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
    dest: PropTypes.shape({
      suburb: PropTypes.string,
      postcode: PropTypes.string,
    }).isRequired,
    refetch: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    const cart = get(props.viewer, 'cart', {});
    const checkoutSnapshot = get(props.viewer, 'cart.checkoutSnapshot');
    const shippingMethod = get(checkoutSnapshot, 'shippingMethod');

    this.state = {
      withInsurance: this.getIsWithInsurance(checkoutSnapshot),
    };

    GA4.addShippingInfo(cart, shippingMethod);
  }

  componentDidUpdate = () => {
    const { form, viewer } = this.props;

    const shippingMethods = get(viewer, 'cart.shippingMethods.edges', []);
    const shippingMethodValue = form.getFieldValue('shippingMethod');

    const { node: selected } = shippingMethods.find(({ node }) => node.code === shippingMethodValue) || {};

    // reset shippingMethod to null if the selected(saved) method no longer avaiable
    if (!selected && shippingMethodValue) {
      form.setFieldsValue({
        shippingMethod: null,
      });
    }
  }

  onChange = (e) => {
    const shippingMethod = e.target.value;
    this.props.refetch({ ...this.props.dest, shippingMethod }, () => {
      const cart = get(this.props.viewer, 'cart', {});
      GA4.addShippingInfo(cart, shippingMethod);
    });
  }

  getIsWithInsurance = (checkoutSnapshot) => {
    const shippingMethod = get(checkoutSnapshot, 'shippingMethod', "");

    if (shippingMethod && shippingMethod.includes("_insurance")) {
      return true;
    }

    return false;
  }

  getWarningMsg = (cart, shippingMethods) => {
    if (!cart) {
      return "Your cart is empty.";
    } else if (shippingMethods.length === 0) {
      return "Enter your address to see your shipping options.";
    }

    return null;
  }

  toggleInsurance = () => {
    const allMethods = get(this.props.viewer, 'cart.shippingMethods.edges', []);
    const method = this.props.form.getFieldValue('shippingMethod');

    if (method) {
      let newMethod = '';
      if (!this.state.withInsurance) {
        if (method.endsWith(PRI_SUFFIX)) {
          newMethod = [method.slice(0, -PRI_SUFFIX.length), INS_SUFFIX, PRI_SUFFIX].join('');
        } else {
          newMethod = `${method}${INS_SUFFIX}`;
        }
      } else {
        newMethod = method.replace(INS_SUFFIX, '');
      }
      const hasMethod = allMethods.find(({ node }) => node.code.includes(newMethod));

      this.props.form.setFieldsValue({
        shippingMethod: hasMethod ? newMethod : method,
      }, () => {
        const shippingMethod = this.props.form.getFieldValue('shippingMethod');

        this.props.refetch({ shippingMethod });
      });
    }

    this.setState({ withInsurance: !this.state.withInsurance });
  }


  pickUpSelect = (stores, lines) => {
    const states = groupBy(stores, 'node.state');
    const hasBulkyGoods = lines.filter(({ node }) => node.product.bulkyGood);

    return Object.keys(states).sort().map((key) => (
      <OptGroup key={key} label={key}>
        {states[key].map(({ node: store }) => {
          const canPickup = !(store.excludeBulkyGood && hasBulkyGoods.length || !store.canPickup);

          return (
            <Option key={store.id} value={store.id}>
              {store.name}
              {!canPickup && (
                <span style={{ color: '#cb0000', fontSize: '12px', marginLeft: '10px', float: 'right' }}>
                  Click & Collect Unavailable
                </span>
              )}
              {canPickup && (
                <StockInfo
                  store={store}
                  style={{
                    display: 'inline-block',
                    fontSize: '12px',
                    fontWeight: '700',
                    color: 'white',
                    padding: '0px 5px',
                    marginRight: '10px',
                    float: 'right',
                  }}
                />
              )}
            </Option>
          )
        })}
      </OptGroup>
    ));
  }

  renderShippingMethods = ({ form, shippingMethods }) => {
    const { getFieldDecorator } = form;
    const shippingMethodValue = form.getFieldValue('shippingMethod');

    shippingMethods = shippingMethods.filter(({ node }) => node.code !== "storepickup");
    const { node: selected } = shippingMethods.find(({ node }) => node.code === shippingMethodValue) || {};

    return (
      <div>
        <Row gutter={[10, 10]}>
          <Col xs={24}>
            <FormItem
              style={{ display: 'block', margin: '0px' }}
            >
              {getFieldDecorator('shippingMethod', {
                rules: [
                  { required: true, message: 'Required' },
                ],
              })(
                <RadioGroup onChange={this.onChange} style={{ display: 'block' }}>
                  <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
                    {shippingMethods.map(({ node: method }) => {
                      let style = {};

                      if (method.code.includes('priority')) {
                        style = { color: '#0089B6', fontWeight: 700 };
                      }

                      return (
                        <Radio style={radioStyle} method={method} value={method.code} key={method.code}>
                          <span style={style}>{method.title}</span> <span className="price">{method.priceFormatted}</span>
                        </Radio>
                      );
                    })}
                  </div>
                </RadioGroup>
              )}
            </FormItem>

            {shippingMethodValue !== 'storepickup' && selected && selected.forklift && (
              <div>
                <hr />
                <div style={{ color: "#cb0000", lineHeight: '20px' }}>
                  WARNING - A tailgate service is required to unload the heavy goods unless you have a forklift.
                </div>
                <FormItem>
                  {getFieldDecorator('forklift', {
                    valuePropName: 'checked',
                    rules: [
                      { required: true, message: 'Required' },
                    ],
                  })(
                    <RadioGroup onChange={(e) => {
                      this.props.refetch({ forklift: e.target.value });
                    }}
                    >
                      <Radio
                        value
                      >
                        <b>Yes, Tailgate service required + <span className="price">$88.00</span></b>
                      </Radio>

                      <br />

                      <Radio
                        value={false}
                      >
                        <b>No, I have a forklift</b>
                      </Radio>
                    </RadioGroup>
                  )}
                </FormItem>
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { form, viewer } = this.props;
    const { withInsurance } = this.state;
    const cart = get(viewer, 'cart');
    const shipping = get(viewer, 'cart.shippingMethods.edges', []);

    const shippingMethods = shipping.filter(({ node }) => {
      if (node.code === 'storepickup') {
        return false;
      }

      const hasInsurance = node.code.includes("_insurance");

      if (withInsurance) {
        return hasInsurance;
      }

      return !hasInsurance;
    });
    const terms = get(viewer, "cart.globalFreeshippingTerms");
    const isStorepickup = form.getFieldValue('shippingMethod') === 'storepickup';
    const hasInsMethod = shipping.find(({ node }) => node.code.includes("_insurance"));

    if (isStorepickup) {
      return null;
    }

    const warningMsg = this.getWarningMsg(cart, shippingMethods);

    return (
      <div className='section-border'>
        <h2>Shipping</h2>

        {warningMsg ? (
          <>
            <p style={{ color: '#cb0000' }}>
              <InfoCircleFilled style={{ marginRight: '8px' }} />
              {warningMsg}
            </p>
          </>
        ) : (
          <>
            {terms && <p>{terms}</p>}
            {(!isStorepickup && hasInsMethod) && <Checkbox onChange={this.toggleInsurance} checked={withInsurance}>With Insurance</Checkbox>}
            {this.renderShippingMethods({ form, shippingMethods })}
          </>
        )}
      </div>
    );
  }
}
export default createFragmentContainer(ShippingView, {
  viewer: graphql`
    fragment ShippingView_viewer on Customer {
      id
      email
      myStore {
        id
      }
      cart {
        grandTotal
        lines(first: 999) @connection(key: "CartView_lines") {
          edges {
            node {
              id
              name
              unitPrice
              quantity
              product {
                sku
                name
                model
                onlineOnly
                bulkyGood
                stockAvailable
                limitedStock
                brand {
                  id
                  name
                }
              }
            }
          }
        }
        checkoutSnapshot
        globalFreeshippingTerms
        shippingMethods(first: 20, street: $street, suburb: $suburb, city: $city, postcode: $postcode) {
          edges {
            node {
              code
              title
              priceFormatted
              forklift
              extra
            }
          }
        }
        stores(first: 9999) {
          edges {
            node {
              id
              name
              address
              city
              postcode
              region
              state
              description
              phone
              lat
              lng
              canPickup
              excludeBulkyGood
              stocks {
                productId
                stock
              }
              hours {
                monday {
                  open
                  close
                }
                tuesday {
                  open
                  close
                }
                wednesday {
                  open
                  close
                }
                thursday {
                  open
                  close
                }
                friday {
                  open
                  close
                }
                saturday {
                  open
                  close
                }
                sunday {
                  open
                  close
                }
              }
            }
          }
        }
      }
    }
  `,
});
